<template>
<KTCard>
  <template #title>
    <div class="d-flex align-items-center flex-wrap mr-2">
      <!--begin::Title-->
      <h5 class="text-dark font-weight-bold mt-2 mb-2 mr-5 d-none d-sm-block">{{ $t('SERVICES_CATEGORY.TITLE') }}</h5>
      <!--end::Title-->
      <!--begin::Separator-->
      <div class="subheader-separator subheader-separator-ver mt-2 mb-2 mr-5 bg-gray-200 d-none d-sm-block" />
      <!--end::Separator-->
      <!--begin::Search Form-->
      <div class="d-flex align-items-center" id="kt_subheader_search">
        <span v-if="totalCount" class="text-dark-50 font-weight-bold mr-5" id="kt_subheader_total">{{ totalCount }} {{ $t('BASE.TOTAL') }}</span>
        <form @submit.stop.prevent="">
          <b-input-group class="input-group input-group-sm input-group-solid" style="max-width: 175px">
            <InputForm
              v-model="search"
              id="kt_subheader_search_form"
              trim
              type="text"
              debounce="500"
              :placeholder="$t('BASE.SEARCH')"
            />
            <b-input-group-append>
              <span class="input-group-text">
                <span class="svg-icon">
                  <inline-svg src="/media/svg/icons/General/Search.svg" />
                </span>
              </span>
            </b-input-group-append>
          </b-input-group>
        </form>
      </div>
      <!--end::Search Form-->
    </div>
  </template>
  <template #toolbar>
    <div v-permission="['edit_services']" class="example-tools justify-content-center">
      <router-link :to="{ name: 'servicesCategoriesCreate' }" class="btn btn-light-success font-weight-bold btn-sm">
        <span class="svg-icon btn-light-success">
          <inline-svg src="/media/svg/icons/Files/File-plus.svg" />
        </span>
        {{ $t('SERVICES_CATEGORY.NEW') }}
      </router-link>
    </div>
  </template>
  <template #body>
    <Table v-cloak :fields="fields" :items="items" :busy.sync="loading">
      <!--<template v-slot:cell(email)="data">-->
      <!--&lt;!&ndash;{{ data.item.email }}&ndash;&gt;-->
      <!--</template>-->
      <template #cell(name)="data">
        <div style="min-width: 150px">
          <span class="text-dark-75 font-weight-bolder font-size-lg mb-0">{{ data.item.name }}</span>
        </div>
      </template>
      <template #cell(parent)="data">
        <div style="min-width: 150px">
          <span class="text-dark-75 font-weight-bolder font-size-lg mb-0">{{ data.item.parent ? data.item.parent.name : '' }}</span>
        </div>
      </template>
      <template #cell(actions)="data">
        <div style="min-width: 80px">
          <router-link
            v-permission="['edit_services']"
            :to="{ name: 'servicesCategoriesEdit', params: { id: data.item.uuid }}"
            class="btn btn-icon btn-light-info btn-sm mr-3"
          >
            <span class="svg-icon btn-light-primary">
              <inline-svg src="/media/svg/icons/Communication/Write.svg" />
            </span>
          </router-link>
          <div v-permission="['edit_services']" class="btn btn-icon btn-light-danger btn-sm" @click="onDelete(data.item)">
            <span class="svg-icon svg-icon-md btn-light-info">
              <inline-svg src="/media/svg/icons/Home/Trash.svg" />
            </span>
          </div>
        </div>
      </template>
    </Table>
    <div v-if="totalCount && params.pagination.limit < totalCount" class="mt-3">
      <Paginations v-model="params.pagination.page"
                   :total-rows="totalCount"
                   :per-page="params.pagination.limit"
                   align="right"
      />
    </div>
  </template>
</KTCard>
</template>

<script>
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
import Repo from '@/core/repository/company/serviceCategoriesRepository'
import Table from '../../../../../components/UI/Table'
import Paginations from '../../../../../components/UI/Paginations'
import InputForm from '../../../../../components/forms-items/input'
import KTCard from '../../../../content/Card'
import baseList from '../../../../../mixins/baseList'

export default {
  name: 'ServicesCategoriesIndex',
  components: {
    KTCard,
    InputForm,
    Paginations,
    Table, 
  },
  mixins: [baseList],
  data() {
    return {
      fields: [
        {
          key: 'name',
          label: this.$t('SERVICES_CATEGORY.NAME'),
          sortable: false,
        },
        {
          key: 'parent',
          label: this.$t('SERVICES_CATEGORY.PARENT'),
          sortable: false,
        },
        {
          key: 'actions',
          label: this.$t('BASE.ACTIONS'),
          class: 'text-right',
          sortable: false,
        },
      ],
    }
  },
  computed: {
    Repo() {
      return Repo
    },
  },
  watch: {
    search(v) {
      this.params.search = v
      if (!v) delete this.params.search
      this.reFetchData()
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: this.$t('PAGE.SERVICES_GROUP'),
        route: 'categories', 
      },
      { title: this.$t('PAGE.SERVICES_CATEGORIES') },
    ])
  },
}
</script>

<style scoped>

</style>
